import React from "react";
import img8 from "../../images/products/switch.jpg";
import firewall from "../../images/products/firewall.jpg";
import banner from "../../images/products/products.png";
import { useNavigate } from "react-router-dom";
import { useData } from "../../blogContext/Context";
import data from "../../pages/data/Product.json";

function ProductPage() {
  const navigate = useNavigate();
  const prod_data = data;
  return (
    <div className="product-page w-full ">
      <div className="w-full h-screen max-[500px]:h-[50vh] bg-black bg-opacity-25">
        <img src={banner} alt="img" className="w-full h-[100%]  " />
        <div className=" w-full absolute flex flex-col my-5 gap-4 px-10 bottom-[10%] max-[500px]:bottom-[55%]">
          <span className="text-white text-6xl font-bold max-[500px]:text-3xl ">
            Our Products
          </span>
          <span className="w-40 h-2 rounded-full bg-white max-[500px]:h-1"></span>
        </div>
      </div>
      <div className="w-full grid grid-cols-3 max-[650px]:grid-cols-1 max-[1200px]:grid-cols-2 py-32 px-10 gap-6">
        {prod_data.map((data) => (
          <div
            onClick={() => navigate(`/prod_detail/${data.id}`)}
            className="w-full py-12 px-5 border-t-4 border-cyan-400 "
          >
            <div className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer">
              <p className="text-white mb-4">
                <span className="text-2xl font-medium">{data.head_name}</span>
              </p>
              <img
                src={data.img}
                alt="img"
                className="rounded-3xl h-[220px] w-full"
              />
            </div>
          </div>
        ))}
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer">
            <a href="https://www.devray.in" target="_blank">
              <p className="text-white mb-4">
                <span className="text-2xl font-medium">DevRay Switches</span>
              </p>
              <img
                src={img8}
                alt="img"
                className="rounded-3xl h-[220px] w-full"
              />
            </a>
          </div>
        </div>

        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer">
            <a href="https://www.devray.in" target="_blank">
              <p className="text-white mb-4">
                <span className="text-2xl font-medium">Next Gen-FIREWALL</span>
              </p>
              <img
                src={firewall}
                alt="img"
                className="rounded-3xl h-[220px] w-full"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductPage;
