import React, { useEffect, useState } from "react";
import maple_doc from "../../images/products/maple_doc.png";
import { useParams } from "react-router-dom";
import data from "../../pages/data/Product.json";

function ProdDetail() {
  const { prod_id } = useParams();
  const prod_data = data;
  const [prod, setProd] = useState(null);
  console.log(prod_id);
  useEffect(() => {
    const chooseProd = prod_data.find((item) => item.id == prod_id);
    setProd(chooseProd);
  }, [prod_id, prod_data]);
  console.log(prod);

  if (!prod) {
    console.log("No product found");
    return <p className="text-xl text-white">Loading...</p>;
  }

  return (
    <div className="blog w-full ">
      <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
        <div className="w-full max-[900px]:w-full flex flex-col  gap-5 text-white text-md p-8 text-md">
          <div className="w-full flex items-center justify-center">
            <img
              src={prod.img2}
              alt="img"
              className="w-4/5 max-[400px]:w-full h-[380px] rounded-xl"
            />
          </div>
          <div className="w-full flex text-lg items-center  gap-2">
            {prod.head_name} : {prod.title}
          </div>
          <h1 className="text-white text-2xl font-medium">Introduction</h1>
          <p>{prod.intro_description}</p>
          <h1 className="text-white text-2xl font-medium">{prod.ques}</h1>
          <p>{prod.ques_description}</p>
          {prod.topics.map((data) => (
            <div key={data.id} className="w-full flex flex-col gap-4">
              <h1 className="text-white text-2xl font-medium">{data.title}</h1>

              {data.benefits.map((point, index) => (
                <li key={index}>
                  <span className="font-medium text-white"> {point.title}</span>{" "}
                  {point.content}
                </li>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProdDetail;
