import React, { useEffect } from "react";
import img from "../../images/home-about-img.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
function About() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const navigate = useNavigate();
  return (
    <div className="overflow-hidden home-about w-full grid grid-cols-2 max-[500px]:flex gap-10 justify-center py-10 max-[650px]:px-6 px-20">
      <img
        data-aos="fade-up"
        src={img}
        alt="about-img"
        className="w-full h-full max-[500px]:hidden rounded-xl"
      />
      <div data-aos="fade-up" className="w-full text-white flex flex-col gap-3">
        <p className="text-cyan-400 font-medium">ABOUT US</p>
        <h1 className="text-4xl font-medium max-[800px]:text-2xl">
          Discover Our Journey Protecting Your Digital World With Expertise And
          Care
        </h1>
        <p className="text-xs max-[800px]:text-xs max-[500px]:text-justify">
          Welcome to MapleCloud Technologies, a distinguished
          <span className="text-lg text-cyan-400">"Make in India"</span>
          Original Equipment Manufacturer (OEM) at the forefront of
          cybersecurity solutions. Our commitment to Indigenous innovation
          drives us to deliver a comprehensive digital defense offerings
          designed to secure and protect your digital landscape. MapleCloud
          Technologies takes pride in being a "Make in India" cybersecurity
          We stand as a trusted partner in
          digital security. Join us on a journey of resilience and innovation,
          where each product reflects our dedication to technological
          advancement and the protection of your digital assets. Experience the
          power of indigenous cybersecurity solutions tailored for the modern
          digital landscape.
        </p>
        <button
          onClick={() => navigate("/about")}
          className="bg-cyan-700 text-white w-52 mt-5 text-sm py-3 rounded-md max-[800px]:text-xs max-[800px]:py-2 max-[800px]:w-40"
        >
          Read More
        </button>
      </div>
    </div>
  );
}

export default About;
