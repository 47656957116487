import React from "react";
import iam from "../../images/products/iam.png";
import { useData } from "../../blogContext/Context";

function MCTAuth() {
  const { prod } = useData();
  console.log(prod);
  return (
    <div className="blog w-full ">
      <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-center">
        {
          <div
            key={prod.id}
            className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8"
          >
            <img src={iam} alt="img" className="w-full h-[380px] rounded-xl" />
            <div className="flex items-center gap-2">
              <h1 className="text-white text-2xl font-medium">
                {prod.name} :
              </h1>
              <p className="text-white text-md mt-1">{prod.title}</p>
            </div>
            <h1 className="text-white text-2xl font-medium">Introduction</h1>
            <p>{prod.intro_description}</p>
            <h1 className="text-white text-2xl font-medium">{prod.ques}</h1>
            <p>{prod.ques_description}</p>

            {prod.topics.map((topic, index) => (
              <div key={index}>
                <h1 className="text-white text-2xl font-medium mb-4">
                  {topic.title}
                </h1>

                {topic.benefits.map((benefit) => (
                  <li className="r my-3 text-md">
                    <span className="font-medium text-lg">{benefit.title}</span>{" "}
                    {benefit.content}
                  </li>
                ))}
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  );
}

export default MCTAuth;
