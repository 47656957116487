   
 import React from "react";
 import img from "../../images/service/mang_serv.jpg";
 import { IoPricetagsOutline } from "react-icons/io5";
 
 function ManageService() {
   return (
    <div className="blog w-full ">
    <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
      <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
        <img src={img} alt="img" className="w-full h-[380px] rounded-xl" />
        <h1 className="text-white text-3xl font-medium">Introduction</h1>
        <p>
        At MapleCloud Technologies (MCT), we specialize in identifying and protecting your company's assets and practices. We help develop, document, and implement comprehensive policies and procedures to secure your assets and operations. Our Managed Services team of experts proactively manages technological issues, addressing them innovatively before they impact your organization. We deliver 24/7 customized services that adapt to your evolving needs. With a decade of expertise in IT services, our cost-effective support solutions ensure security, stability, and efficiency across all ICT environments. Our proactive management services maintain optimal network performance and reliability.
        </p>
        <h1 className="text-white text-3xl font-medium">
        Improve Business Performance with MCT Technology Infrastructure Services
        </h1>
        <p>
        MCT Infrastructure Services removes barriers and connects resources to accelerate your transformation journey. We follow a service-oriented approach for end-to-end management of critical infrastructure, ensuring seamless IT support for our customers. Our standard procedures and technical expertise bring innovative solutions with minimal downtime, optimizing the use of servers, networks, and end-user equipment. With data center and remote management services worldwide, we help customers achieve increased ROI, lowered TCO, and proven global delivery.

        </p>
        <h1 className="text-white text-3xl font-medium">
        Features
        </h1>
        <p>
        MapleCloud Technologies offers comprehensive outsourced networking applications and function monitoring. Our range of Management & Monitoring services ensures continued availability and performance throughout the lifecycle of your data center or server room environment. Whether you're an IT team looking to outsource cloud application management, a start-up, or an enterprise with global offices, our expert team will help you bridge the gap and implement the best cloud computing solutions for your business.
        </p>
        <h1 className="text-white text-3xl font-medium">
        Get Started
        </h1>
        <p>
        Enhance your security management and IT infrastructure with Maple Cloud Technologies. Contact us today to learn how we can help secure your assets, optimize your network, and support your business growth through innovative IT solutions.
        </p>
      </div>

      <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
        <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-6 rounded-xl">
          <p className="text-white font-medium text-xl ">
          MCT Managed Services
          </p>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Firewall Managed Service (including SSL decryption, UTM)
            </h1>
            <br />
            <p className="text-white">
            The MCT-managed firewall service presents a comprehensive management solution designed to cater to various third-party firewall technologies. Its primary objective is to assist clients in enhancing the efficiency and security of both their internal and perimeter firewalls. Our approach to firewall management encompasses the critical components of Incident and Event Monitoring & Management. Within our solution framework, an event processor will be strategically deployed on-site, establishing a direct link to the Security Operations Centre, where all incidents are meticulously recorded for precise and timely remediation actions.
            </p>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Security Perimeter Design
            </h1>
            <br />
            <p className="text-white">
            Through our Professional Services Practice, our team of seasoned security architects stands ready to provide comprehensive support across the entire spectrum of security perimeter design, ongoing assistance, and everything in the realm of security. This holistic approach empowers your business to derive optimal advantages. Our robust design expertise.
            </p>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Antivirus Managed Service
            </h1>
            <br />
            <p className="text-white">
            To gain access to network systems, it is imperative that all endpoint devices, including PCs, laptops, mobile devices, and point of sale (POS) devices, adhere to the latest regulatory requirements. At MCT, we provide cutting-edge endpoint protection solutions that take a proactive stance in safeguarding against malicious activities and identifying potential vulnerabilities.
            </p>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Web Security Gateway Managed Service
            </h1>
            <br />
            <p className="text-white">
            Our managed secure web gateway service can keep your staff working with both safety and efficiency and let them forget about the ever-present threat of malware, both known and unknown.
            </p>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Data Discovery Managed Service
            </h1>
            <br />
            <p className="text-white">
            Data Discovery and Management (DDAM) is designed to help organizations gain control over disparate data assets organization-wide. DDAM includes a ‘living’ data inventory, built-in private and sensitive data discovery, and data retention management. DDAM is compatible with structured, semi-structured, and unstructured data.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
   )
 }
 
 export default ManageService
 