 
 
    
 import React from "react";
 import img from "../../images/service/network.jpg";
 import img1 from "../../images/products/docker.png";
 import img2 from "../../images/products/vul_dashboard.png";
 import { IoPricetagsOutline } from "react-icons/io5";
 function Nrc() {
   return (
    <div className="blog w-full ">
    <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
      <div className="w-4/5 max-[900px]:w-full flex flex-col text-white text-md p-8">
        <img src={img} alt="img" className="w-full h-[380px] rounded-xl" />
        <br />
        <h1 className="text-white text-3xl font-medium">Introduction</h1>
        <br />
        <p>
        Improve your time-to-market with MCT's network rollout and commissioning services. Our solutions enable organizations to quickly deploy new network infrastructure and services, reducing the time-to-market for new products, services, and technologies. Rapid deployment allows you to capitalize on market opportunities, gain a competitive edge, and generate revenue sooner.
        </p>
        <br />
        <h1 className="text-white text-3xl font-medium">
        Enhanced Connectivity
        </h1>
        <br />
        <p>
        Our network rollout and commissioning services enhance connectivity by expanding network coverage, capacity, and performance. By deploying additional infrastructure such as towers, antennas, and access points, we help extend network reach, improve signal strength, and enhance connectivity for users and devices.
        </p>
        <br />
        <h1 className="text-white text-3xl font-medium">
        Features
        </h1>
        <br />
        <p className="font-medium text-xl">Optimized Resource Utilization</p>
        <p>
          Servers, VMs, and operating systems can be monitored using
          EventShield's agent-based solution. It provides secure tunnel-based
          agent integration on different OS platforms, including Windows,
          Linux, and macOS. This solution ensures comprehensive monitoring and
          protection across various environments.
        </p>
        <br />
        <p className="font-medium text-xl">Improved Customer Experience
        </p>
        <p>
        We focus on delivering reliable, high-quality network services to enhance the customer experience. By ensuring network reliability, availability, and performance, we help you meet customer expectations, reduce service disruptions, and enhance satisfaction and loyalty.
        </p>
        <br />
        <h1 className="text-white text-3xl font-medium">
        Get Started
        </h1>
        <br />
        <p>
        Enhance your network capabilities with MCT's expert rollout and commissioning services. Contact us today to learn how we can help you quickly and efficiently deploy the infrastructure needed to stay ahead in a competitive market.
        </p>
      </div>

      <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
        <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-6 rounded-xl">
          <p className="text-white font-medium text-xl ">
          MCT Network Rollout & Commissioning
          </p>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Scalability and Flexibility
            </h1>
            <br />
            <p className="text-white">
            Network rollout and commissioning enable organizations to scale their network infrastructure to accommodate growth and changing demand.
            </p>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Risk Mitigation
            </h1>
            <br />
            <p className="text-white">
            Network rollout and commissioning help organizations mitigate risks associated with network deployments, such as compatibility issues, configuration errors, and performance bottlenecks.
            </p>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Compliance and Quality Assurance
            </h1>
            <br />
            <p className="text-white">
            Network rollout and commissioning ensure compliance with industry standards, regulatory requirements, and quality assurance guidelines. By adhering to best practices, conducting quality checks,
            </p>
          </div>
          <div className="w-full ">
            <h1 className="text-cyan-400 text-xl font-medium">
            Operational Efficiency
            </h1>
            <br />
            <p className="text-white">
            Network rollout and commissioning improve operational efficiency by automating deployment processes, streamlining workflows, and optimizing resource allocation.
            </p>
          </div>
        </div>
        <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-4 rounded-xl">
          <p className="text-white font-medium text-2xl ">
          Key Benefits of MCT NRP Solutions
          </p>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Security Enhancement</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Compliance Adherence</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Operational Efficiency</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Cost Savings</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">User Productivity</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Single Sign-On (SSO)</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Reduced IT Workload</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">
            Risk Mitigation
            </p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Password Management</p>
          </div>
          <div className="flex items-center gap-4">
            <IoPricetagsOutline className="text-white text-xl" />
            <p className="text-white  text-md ">Scalability</p>
          </div>
        </div>
      </div>
    </div>
  </div>
   )
 }
 
 export default Nrc
 