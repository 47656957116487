 
 import React from 'react'
 
 function About_banner() {
   return (
    <div id='about_banner' className=' about-banner h-[500px]  w-full px-20 max-[500px]:px-10 pb-10   '>
        <div className='abolute  left-0 pt-40 text-white text-4xl max-[500px]:w-full  font-bold w-2/5 max-[950px]:w-4/5'>
            <h1 className='leading-10  drop-shadow-2xl'>We Secure your Digital Infrastructure with Advanced Cybersecurity Solutions.</h1>
        </div>
    </div>
   )
 }
 
 export default About_banner
 