import React from "react";
import img from "../../images/service/cloud-tech.jpg";
import img1 from "../../images/products/docker.png";
import img2 from "../../images/products/vul_dashboard.png";
import { IoPricetagsOutline } from "react-icons/io5";

function Operation() {
  return (
    <div className="blog w-full ">
      <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
        <div className="w-4/5 max-[900px]:w-full flex flex-col  text-white text-md p-8">
          <img src={img} alt="img" className="w-full h-[380px] rounded-xl" />
          <br />
          <h1 className="text-white text-3xl font-medium">Introduction</h1>
          <i className="text-lg font-medium my-2">
            Centrally Managed and Continual Field Support Services for Your
            Business
          </i>
          <p>
            At MapleCloud Technologies, we recognize the dynamic nature of
            consumer needs and the challenges organizations face in maintaining
            optimal operations. To meet these demands, we offer centrally
            managed and continual field support services, ensuring your network
            services and customer operations function seamlessly and
            cost-effectively. Whether through remote infrastructure management
            or on-field support, our team of experts and partners across various
            geographies are equipped to handle large and complex deployment
            projects.
          </p>
          <br />
          <h1 className="text-white text-3xl font-medium">Our Expertise</h1>
          <br />
          <p>
            MapleCloud Technologies has established itself as a leader in
            managing extensive and intricate roll-out projects across multiple
            locations. Our comprehensive skill set includes:
          </p>
          <br />
          <li>Servers and Platforms</li>
          <li>Storage Solutions</li>
          <li>Switches/Routers</li>
          <li>Server Technicians</li>
          <li>Software and Hardware Engineers</li>
          <li>Desktop Engineers</li>
          <li>Communication Experts</li>
          <br />
          <h1 className="text-white text-3xl font-medium">Features</h1>
          <br />
          <li>
            MapleCloud Technologies has established itself as a leader in
            managing extensive and intricate roll-out projects across multiple
            locations. Our comprehensive skill set includes:
          </li>
          <li>
            Proactive Maintenance: Field technicians conduct regular
            inspections, preventive maintenance, and software updates to ensure
            the reliability and performance of network equipment. This proactive
            approach minimizes downtime, prevents outages, and prolongs the
            lifespan of your network infrastructure.
          </li>
          <li>
            Fault Identification and Resolution: Our field operations teams
            respond swiftly to network faults, alarms, and performance issues.
            By troubleshooting problems onsite, technicians can quickly diagnose
            and resolve network issues, minimizing the impact on users and
            operations.
          </li>
          <br />
          <h1 className="text-white text-3xl font-medium">Get Started</h1>
          <br />
          <p>
            Enhance your network infrastructure with MCT’s centrally managed and
            field support services. Contact us today to learn how we can help
            you maintain optimal network performance and support your business
            growth.
          </p>
        </div>

        <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-6 rounded-xl">
            <p className="text-white font-medium text-xl ">
              MCT Network Field Operations
            </p>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Optimized Performance
              </h1>
              <br />
              <p className="text-white">
                Field operations teams optimize network performance by
                fine-tuning network configurations, adjusting parameters, and
                implementing best practices to maximize throughput, minimize
                latency, and improve overall network efficiency.
              </p>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Quality Assurance
              </h1>
              <br />
              <p className="text-white">
                Field technicians perform quality assurance checks and
                performance testing to verify that network installations meet
                industry standards, regulatory requirements, and service level
                agreements (SLAs). Quality assurance ensures that network
                infrastructure is reliable, secure, and compliant with
                organizational guidelines.
              </p>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Customer Support and Satisfaction
              </h1>
              <br />
              <p className="text-white">
                Field operations teams provide onsite support and assistance to
                customers, helping them troubleshoot connectivity issues,
                configure devices, and resolve service-related concerns. By
                delivering timely and effective support, technicians enhance
                customer satisfaction and loyalty.
              </p>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Asset Management
              </h1>
              <br />
              <p className="text-white">
                Field operations teams manage and maintain an inventory of
                network assets, including routers, switches, cables, and other
                equipment. Asset management helps organizations track equipment
                usage, monitor warranty status, and plan for equipment upgrades
                or replacements as needed.
              </p>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Documentation and Reporting
              </h1>
              <br />
              <p className="text-white">
                Field technicians document network configurations, installation
                procedures, and maintenance activities to maintain accurate
                records and facilitate knowledge transfer. Comprehensive
                documentation enables teams to troubleshoot issues more
                efficiently and provides valuable insights for future network
                planning and optimization.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Operation;
