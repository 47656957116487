import React from "react";
import img from "../../images/service/data-center.jpg";

function DataCenter() {
  return (
    <div className="blog w-full ">
      <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
        <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
          <img src={img} alt="img" className="w-full h-[380px] rounded-xl" />
          <h1 className="text-white text-3xl font-medium">Introduction</h1>
          <p>
            Transform your data center to drive business innovation and growth
            with MCT. We provide a foundation for a flexible and scalable data
            center that aligns with today's rapid digital transformation. As the
            central hub for storage, servers, and network capabilities, your
            data center plays a crucial role in your business productivity. Any
            limitations can directly impact your operations. With over a decade
            of expertise in building and designing facilities for enterprises
            globally, we can transform your existing infrastructure into a
            valuable corporate asset that accelerates your business growth. Our
            experienced management and field technicians are dedicated to
            delivering the best service and support.
          </p>
          <h1 className="text-white text-3xl font-medium">Features</h1>
          <p>Our Tailored Service Plans Includes: </p>
          <li>
            Preventive and Corrective Maintenance: Ensure your data center runs
            smoothly with proactive and responsive maintenance.
          </li>
          <li>
            24/7 Support Services: Ensure your data center runs smoothly with
            proactive and responsive maintenance.
          </li>
          <li>
            Customized Solutions: Integrate technology, people, and processes to
            meet your unique needs.
          </li>
          <p>
            At MapleCloud Technologies, we customize and develop service plans
            with our team of experts and selected service affiliates. We
            collaborate with each service provider to ensure seamless
            integration and operation, giving you a single point of contact for
            all your business solutions..
          </p>
          <h1 className="text-white text-3xl font-medium">Get Started</h1>
          <p>
            Elevate your data center capabilities with MCT. Contact us today to
            learn how we can help you transform your infrastructure into a
            powerful engine for business growth and innovation.
          </p>
        </div>

        <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-6 rounded-xl">
            <p className="text-white font-medium text-xl ">MCT Data Center</p>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">Scalability</h1>
              <br />
              <p className="text-white">
                Data centers provide scalable infrastructure resources, allowing
                organizations to easily expand or shrink their computing
                capacity based on changing demands. This scalability enables
                businesses to adapt to growth or fluctuations in workload
                without the need for significant upfront investments in
                hardware.
              </p>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Reliability and Redundancy
              </h1>
              <br />
              <p className="text-white">
                Data centers are designed with redundancy and failover
                mechanisms to ensure high availability of services. They
                typically feature backup power supplies, cooling systems, and
                network connectivity to minimize the risk of downtime due to
                hardware failures, power outages, or network disruptions.
              </p>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">Security</h1>
              <br />
              <p className="text-white">
                Data centers implement robust physical and digital security
                measures to protect sensitive information and assets. These
                measures may include access controls, surveillance systems,
                biometric authentication, encryption, and firewalls to safeguard
                against unauthorized access, data breaches, and cyber-attacks.{" "}
              </p>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Cost Efficiency
              </h1>
              <br />
              <p className="text-white">
                By leveraging economies of scale, data centers can provide
                cost-effective infrastructure solutions compared to maintaining
                on-premises IT environments. Organizations can benefit from
                shared resources, lower energy consumption, reduced maintenance
                overhead, and predictable operational expenses through data
                center services.
              </p>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Performance Optimization
              </h1>
              <br />
              <p className="text-white">
                Data centers are equipped with high-performance computing
                hardware, networking equipment, and storage systems to deliver
                optimal performance for critical workloads. They often employ
                advanced technologies such as solid-state drives (SSDs),
                high-speed interconnects, and distributed computing
                architectures to accelerate data processing and improve
                responsiveness.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataCenter;
