import comp from "../../images/products/comp.jpg";


function Log() {
  return (
    <div className="blog w-full ">
      <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
        <div className="w-4/5 max-[900px]:w-full flex flex-col gap-8 text-white text-md p-8">
          <img src={comp} alt="img" className="w-full h-[400px] rounded-xl" />
          <h1 className="text-white text-3xl font-medium">Overview</h1>
          <p>
            MapLog, developed by MapleCloud Technologies, is a leading
            proprietary log management platform designed for the effective
            management of vast quantities of log data in real-time. Leveraging
            advanced data processing and analytics technologies, MapLog enables
            organizations to efficiently gather, store, analyze, and manage
            terabytes of machine data, offering actionable insights for improved
            decision-making. MapLog provides enhanced security features,
            dedicated support, and customized solutions tailored to meet the
            specific needs of enterprises.
          </p>
          <h1 className="text-white text-3xl font-medium">Features</h1>
          <li>
            Log Collection : MapLog's extensive compatibility with various log
            collection protocols, including Syslog, GELF, and Beats, allows it
            to aggregate log data from virtually any source, providing a unified
            view of distributed systems.
          </li>
          <li>
            Real-time Analysis & Monitoring : Offers the ability to monitor log
            streams in real time, enabling immediate detection of anomalies or
            issues, facilitating swift responses to operational or security
            events.
          </li>
          <li>
            Advanced Search & Filtering :  Empowers users with a powerful
            search engine, supporting complex queries and filtering to drill
            down into the specifics of log data, making data exploration both
            efficient and effective.
          </li>
          <li>
            Alerting & Notifications : Features a flexible alerting system
            capable of triggering notifications based on predefined criteria,
            ensuring that critical issues are promptly addressed to minimize
            potential impact.
          </li>
          <li>
            Scalable & Fault-tolerant Architecture : Designed to scale
            horizontally, MapLog can handle data ingestion rates of several
            terabytes per day, ensuring reliability and performance even in the
            most demanding environments.
          </li>
        </div>

        <div className="w-2/5 max-[900px]:w-full p-4 mt-14 flex flex-col gap-14">
          <div className="w-full px-10 max-[500px]:px-4 py-6 -mt-10 bg-stone-600 bg-opacity-15 flex flex-col  gap-6 rounded-xl">
            <p className="text-white font-medium text-xl ">
              Benefits of MapleLogs
            </p>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Operational Efficiency
              </h1>
              <br />
              <li className="text-white">
                Automates the collection, normalization, and analysis of log
                data, reducing the time and effort required for data management
                and enabling a focus on higher-value activities.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Enhanced Security Posture
              </h1>
              <br />
              <li className="text-white">
                Provides deep insights into security-related log data, aiding in
                the early detection of threats and vulnerabilities, thus
                enhancing an organization's security posture.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Compliance & Auditing
              </h1>
              <br />
              <li className="text-white">
                Facilitates compliance with regulatory standards by ensuring
                that log data is securely stored, easily accessible, and
                analyzable for audit purposes, simplifying compliance reporting.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                Optimized User Experience
              </h1>
              <br />
              <li className="text-white">
                Delivers a streamlined user interface for managing logs,
                creating dashboards, and configuring alerts, significantly
                enhancing user satisfaction and productivity.
              </li>
            </div>
            <div className="w-full ">
              <h1 className="text-cyan-400 text-xl font-medium">
                user satisfaction and productivity. Extensive Integration
                Capabilities
              </h1>
              <br />
              <li className="text-white">
                Supports a wide range of plugins and integrations, allowing
                MapLog to function seamlessly within existing IT ecosystems,
                enhancing data visibility and operational insights.
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Log;
