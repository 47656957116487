import React, { useEffect } from "react";
import box1 from "../../images/Group 65.png";
import box2 from "../../images/Cyber Security.png";
import box3 from "../../images/Backup.png";
import shield from "../../images/Service_shield_img.svg";
import AOS from "aos";
import "aos/dist/aos.css";
function Services() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div className="home-service pb-10  w-full px-20 max-[730px]:px-6   pt-14 flex flex-col gap-20 items-center relative">
      <img
        src={shield}
        alt="img"
        className="absolute left-0 top-0 z-10 max-[950px]:hidden"
      />
      <div className="w-3/5 max-[730px]:w-full flex items-center gap-3 flex-col">
        <p className="text-lg text-cyan-400 font-medium">OUR SERVICES</p>
        <h1 className="text-white font-medium text-5xl text-center max-[730px]:text-3xl">
          Protecting Your Digital <br />
          Assets Expertly
        </h1>
        <p className="text-center text-sm text-gray-200 mt-2 max-[730px]:text-xs">
          Safeguard your business's digital infrastructure with
          industry-leading cybersecurity solutions. Our comprehensive protection
          strategies ensure your valuable data and systems remain secure in
          today's evolving threat landscape.
        </p>
      </div>
      <div className="w-full grid grid-cols-3 gap-8  items-cener justify-center  max-[500px]:grid-cols-1 ">
        <div data-aos="zoom-in" className="w-full  relative">
          <img
            src={box1}
            alt="icon"
            className=" max-[500px]:left-[44%] max-[730px]:w-12 w-12 h-12 absolute left-[43%] -top-6 bg-black  rounded-full pt-2 pb-2 px-2 border-2 border-cyan-500"
          />
          <div className="bg-neutral-600 hover:border-2 hover:border-cyan-500 bg-opacity-35 cursor-pointer bg-opacity-3 flex flex-col items-center gap-3 pt-14 pb-5  max-[730px]:pt-10 rounded-xl  text-white 5 w-full px-2">
            <p className="text-xl font-medium text-center text-white max-[730px]:text-sm">
              Cyber Security Assessment
            </p>
            <p className="text-sm text-center text-gray-300 max-[730px]:text-xs">
              Complete analysis of your security infrastructure to identify and
              address vulnerabilities.
            </p>
          </div>
        </div>
        <div data-aos="zoom-in" className="w-full  relative">
          <img
            src={box2}
            alt="icon"
            className=" max-[500px]:left-[44%] max-[730px]:w-12 w-12 h-12 absolute left-[43%] -top-6 bg-black rounded-full pt-2 pb-2 px-2 border-2 border-cyan-500"
          />
          <div className="bg-neutral-600  bg-opacity-35 hover:border-2 hover:border-cyan-500 cursor-pointer bg-opacity-3 flex flex-col items-center gap-3 pt-14  max-[730px]:pt-10 pb-5 px-3 rounded-xl  text-white 5 w-full">
            <p className="text-xl  font-medium text-center text-white max-[730px]:text-sm">
              Intrusion Detection and Prevention
            </p>
            <p className="text-sm text-center text-gray-300 max-[730px]:text-xs">
              24/7 monitoring and blocking of threats before they impact your
              systems.
            </p>
          </div>
        </div>
        <div data-aos="zoom-in" className="w-full relative">
          <img
            src={box3}
            alt="icon"
            className=" max-[500px]:left-[44%] max-[730px]:w-12 w-12 h-12 absolute left-[43%] -top-6 bg-black rounded-full pt-2 pb-2 px-2 border-2 border-cyan-500"
          />
          <div className="bg-neutral-600  bg-opacity-35 hover:border-2 hover:border-cyan-500 cursor-pointer bg-opacity-3 flex flex-col items-center gap-3 pt-14 max-[730px]:pt-10  pb-5 px-3  rounded-xl  text-white 5 w-full">
            <p className="text-xl font-medium text-center text-white max-[730px]:text-sm">
              Incident Response and Recovery
            </p>
            <p className="text-sm text-center text-gray-300 max-[730px]:text-xs">
              Swift response to security incidents with minimal disruption to
              your business.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
