import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Home from "./pages/home/Home";
import AboutPage from "./pages/about/AboutPage";
import Blog from "./pages/Blog/Blog";
import Techblog from "./pages/Blog/Techblog";
import Cloudsec from "./pages/Blog/Cloudsec";
import TopCyberSec from "./pages/Blog/TopCyberSec";
import AInt from "./pages/Blog/AInt";
import ProductPage from "./pages/Products/ProductPage";
import Siem from "./pages/Products/Siem";
import MapleDoc from "./pages/Products/MapleDoc";
import Nms from "./pages/Products/Nms";
import MailBridge from "./pages/Products/MailBridge";
import Rime from "./pages/Products/Rime";
import Ncms from "./pages/Products/Ncms";
import Sdwan from "./pages/Products/Sdwan";
import Nac from "./pages/Products/Nac";
import Log from "./pages/Products/Log";
import Service from "./pages/services/Service";
import Cloud from "./pages/services/Cloud";
import DataCenter from "./pages/services/DataCenter";
import Ict from "./pages/services/Ict";
import ManageService from "./pages/services/ManageService";
import Mtech from "./pages/services/Mtech";
import Network from "./pages/services/Network";
import Nrc from "./pages/services/Nrc";
import Operation from "./pages/services/Operation";
import Contact from "./pages/contactpage/Contact";
import ProdDetail from "./pages/Products/ProdDetail";
import MCTAuth from "./pages/Products/MctAuth";
import NotFound from "./components/NotFound";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/about"
            element={
              <Layout>
                <AboutPage />
              </Layout>
            }
          />
          <Route
            path="/blog"
            element={
              <Layout>
                <Blog />
              </Layout>
            }
          />
          <Route
            path="/tech_blog"
            element={
              <Layout>
                <Techblog />
              </Layout>
            }
          />
          <Route
            path="/cloudsec"
            element={
              <Layout>
                <Cloudsec />
              </Layout>
            }
          />
          <Route
            path="/topcybersec"
            element={
              <Layout>
                <TopCyberSec />
              </Layout>
            }
          />
          <Route
            path="/artint"
            element={
              <Layout>
                <AInt />
              </Layout>
            }
          />
          <Route
            path="/productpage"
            element={
              <Layout>
                <ProductPage />
              </Layout>
            }
          />
          <Route
            path="/siem"
            element={
              <Layout>
                <Siem />
              </Layout>
            }
          />
          <Route
            path="/mct_auth"
            element={
              <Layout>
                <MCTAuth />
              </Layout>
            }
          />
          <Route
            path="/prod_detail/:prod_id"
            element={
              <Layout>
                <ProdDetail />
              </Layout>
            }
          />
          <Route
            path="/mapledoc"
            element={
              <Layout>
                <MapleDoc />
              </Layout>
            }
          />
          <Route
            path="/nms"
            element={
              <Layout>
                <Nms />
              </Layout>
            }
          />
          <Route
            path="/mailbrige"
            element={
              <Layout>
                <MailBridge />
              </Layout>
            }
          />
          <Route
            path="/rim"
            element={
              <Layout>
                <Rime />
              </Layout>
            }
          />
          <Route
            path="/ncms"
            element={
              <Layout>
                <Ncms />
              </Layout>
            }
          />
          <Route
            path="/sdwan"
            element={
              <Layout>
                <Sdwan />
              </Layout>
            }
          />
          <Route
            path="/nac"
            element={
              <Layout>
                <Nac />
              </Layout>
            }
          />
          <Route
            path="/log"
            element={
              <Layout>
                <Log />
              </Layout>
            }
          />
          <Route
            path="/services"
            element={
              <Layout>
                <Service />
              </Layout>
            }
          />
          <Route
            path="/cloud"
            element={
              <Layout>
                <Cloud />
              </Layout>
            }
          />
          <Route
            path="/data_center"
            element={
              <Layout>
                <DataCenter />
              </Layout>
            }
          />
          <Route
            path="/ict"
            element={
              <Layout>
                <Ict />
              </Layout>
            }
          />
          <Route
            path="/manageservice"
            element={
              <Layout>
                <ManageService />
              </Layout>
            }
          />
          <Route
            path="/mtech"
            element={
              <Layout>
                <Mtech />
              </Layout>
            }
          />
          <Route
            path="/network"
            element={
              <Layout>
                <Network />
              </Layout>
            }
          />
          <Route
            path="/nrc"
            element={
              <Layout>
                <Nrc />
              </Layout>
            }
          />
          <Route
            path="/operation"
            element={
              <Layout>
                <Operation />
              </Layout>
            }
          />
          <Route
            path="/contactpage"
            element={
              <Layout>
                <Contact />
              </Layout>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
